<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>Crear Parametro de la Aplicación</h3>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
              class="contact-form"
              method="post"
              v-on:submit.prevent="handleSubmit(actualizar)"
          >
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Etiqueta:
                  </label>
                  <validation-provider
                      name="Etiqueta"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.etiqueta"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Valor:
                  </label>
                  <validation-provider
                      name="Valor"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.valor"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>


            <div class="form-group">
              <button type="submit" class="btn btn-primary">
                <span class="cil-save btn-icon mr-2"></span>
                Guardar
              </button>

              <router-link to="/configuracionApp/List">
                <CButton class="ml-2" color="secondary">
                  <span class="cil-x-circle btn-icon mr-2"></span>
                  Cancelar
                </CButton>
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      registro: {
        etiqueta: '',
        valor: '',
      },

      error: 0,
      errorMostrarMsj: []
    }
  },

  methods: {
    async registrar() {
      try {

        let url ="app-configuraciones";
        let response = await httpService.post(url, this.registro);


        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        this.$router.back()
      } catch (error) {
        console.log(error);
      }
    },

  },
}
</script>